import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import { render } from "react-dom";
import { io } from "socket.io-client";
import ReactPlayer from "react-player";

const houseSocket = io();

import "./assets/css/index.css";

import VideoPassiveMode from "./assets/video/mode-passif.mp4";
import VideoCoteTechnique from "./assets/video/cote-technique.mp4";
import VideoGoogleOff from "./assets/video/ok_google_off.mp4";
import VideoGoogleOn from "./assets/video/ok_google_on.mp4";

class Index extends Component{

    constructor(props){
        super(props);
        this.state = {
            id: "",
            mode: "passive",
            device: "",
            app: { display: "hide"},
            light: { state : "off" },
            stores: { state : "off" },
            heater: { state : "off" },
            video: {
                display: "show",
                name: "screensaver",
                url: VideoPassiveMode,
                playing: true,
                width: "100%",
                loop: true,
                muted: true,
                volume: "0"
            }
        };
        this.idleTimer = null;
        this.activeMode = this.activeMode.bind(this);
        this.stayActive = this.stayActive.bind(this);
        this.passiveMode = this.passiveMode.bind(this);
        this.playAssistantVideo = this.playAssistantVideo.bind(this);
        this.playTechniqueVideo = this.playTechniqueVideo.bind(this);
        this.onVideoStart= this.onVideoStart.bind(this);
        this.onVideoEnded = this.onVideoEnded.bind(this);
        houseSocket.emit("create-house");
    }

    componentDidMount() {

        houseSocket.on("callbackevent", data => {

            this.setState({ app: { display: "show"}, video : { display: "hide", name: "", url: "", playing: false, width: "0", loop: false, muted: true, volume: "0"}});
            //document.getElementById("main").classList.add("zoom-"+data.device);
            if(data.device == "light") this.setState({ light: { state: this.state.light.state == "on" ? "off" : "on" }});
            else this.setState({ [data.device] : { state: data.state }});
            //setTimeout(() => {
                //document.getElementById("main").classList.remove("zoom-"+data.device);
            //}, 6000);
            this.idleTimer.reset();

        });

        houseSocket.on("phoneconnection", (data) => {

            this.setState({ app: { display: "show"}, video : { display: "hide", name: "", url: "", playing: false, width: "0", loop: false, muted: true, volume: "0"}});

            this.idleTimer.reset();

            houseSocket.emit("update-remote", {
                device: "light",
                state: this.state.light.state
            });

            houseSocket.emit("update-remote", {
                device: "stores",
                state: this.state.stores.state
            });

            houseSocket.emit("update-remote", {
                device: "heater",
                state: this.state.heater.state
            });


        });

    }

    stayActive(){

        console.log('stayActive....');
        this.idleTimer.reset();
    }

    passiveMode(){

        console.log('passiveMode....');
        this.setState({
            mode: "passive",
            app: { display: "hide"},
            light: { state: "off"},
            stores: { state: "off"},
            heater: { state: "off"},
            video: {
                display: "show",
                name: "screensaver",
                url: VideoPassiveMode,
                playing: true,
                width: "100%",
                loop: true,
                muted: false,
                volume: "1"
            }
        });

    }

    activeMode(){

       console.log('activeMode....');
        this.setState({
            mode: "active",
            app: { display: "show"},
            video: {
                display: "hide",
                name: "",
                url: null,
                playing: false,
                width: "0",
                loop: false,
                muted: true,
                volume: "0"
            }
        });

    }


    playAssistantVideo(){
        this.setState({ video: { display: "show", name: "assistant", url: this.state.light.state == "on" ? VideoGoogleOff : VideoGoogleOn, playing: true, width: "53.4%", loop: false, muted: false, volume: "1"}});
    }

    playTechniqueVideo(){
        this.setState({ video: { display: "show", name: "technique", url: VideoCoteTechnique, playing: true, width: "100%", loop: false, muted: false}});
    }

    onVideoStart() {
        if (this.state.video.name != "screensaver") {
            this.idleTimer.reset();
        }
        if (this.state.video.name == "assistant") {
            houseSocket.emit("update-remote", {
                device: "light",
                state: this.state.light.state
            });
            this.setState( {light: { state: this.state.light.state == "on" ? "off" : "on" }});
        }
    }

    onVideoEnded(){

        if (this.state.video.name != "screensaver") {
            this.setState({
                video: {
                    display: "hide",
                    name: "",
                    url: "",
                    playing: false,
                    width: "0",
                    loop: false,
                    muted: true,
                    volume: "0"
                }
            });

            this.idleTimer.reset();
        }
    }


    render(){
        return (
            <div className="index">
                <div id="video-container" className={this.state.video.display} onClick={this.activeMode}>
                    <ReactPlayer name={this.state.video.name}
                                 className={this.state.video.name}
                                 playing={this.state.video.playing}
                                 url={this.state.video.url}
                                 loop={this.state.video.loop}
                                 volume={this.state.video.volume}
                                 muted={this.state.video.muted}
                                 width={this.state.video.width}
                                 height="auto"
                                 onStart={this.onVideoStart}
                                 onEnded={this.onVideoEnded}
                    />
                </div>
                <div id="app" className={this.state.app.display}>
                    <div id="main">
                        <div id="stores" className={this.state.stores.state +" stores"}></div>
                        <div id="house"></div>
                        <div id="light" className={this.state.light.state +" light"}></div>
                        <div id="heater" className={this.state.heater.state +" heater"}></div>
                    </div>
                    <div className="video-buttons">
                        <div id="btnassistant" onClick={this.playAssistantVideo}></div>
                        <div id="btntechnique" onClick={this.playTechniqueVideo}></div>
                    </div>
                </div>
                <IdleTimer ref={ref => { this.idleTimer = ref }} timeout={1000 * 60 * 2} onActive={this.activeMode} onIdle={this.passiveMode} onAction={this.stayActive} />
            </div>
        )
    }
}

render(
    <Index/>,
    document.getElementById("index")
);
